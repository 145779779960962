<template>
	<div class="detail flex-space-between" v-if="pageInfo">
		<div class="left">
			<div class="header flex-direction-column">
				<span>{{ pageInfo.name }}</span>
				<p>{{ pageInfo.create_time }}</p>
			</div>
			<div class="swiper flex-direction-column">
				<el-image style="width: 940px; height: 637px" :src="url" :preview-src-list="pageInfo.images"></el-image>
				<div class="image_list flex">
					<img v-for="(item, index) in pageInfo.images" @click="choiceImage(item)" :key="index" :src="item"/>
				</div>
			</div>
			<div class="source" v-if="pageInfo.source">（来源：{{ pageInfo.source }}）</div>
			<div class="article flex-direction-column">
				<span @click="choiceClick(pageInfo.last.id)" v-if="pageInfo.last">上一篇：{{ pageInfo.last.name }}</span>
				<span @click="choiceClick(pageInfo.next.id)" v-if="pageInfo.next">下一篇：{{ pageInfo.next.name }}</span>
			</div>
		</div>
		<div class="right">
			<div class="title title-vertical flex-space-between">
				<span>相关图片</span>
			</div>
			<div class="r_content flex-direction-column">
				<div class="r_item flex-space-between" @click="relevantClick(item.id)" v-for="item in list" :key="item.id">
					<img :src="item.image"/>
					<div class="desc flex-direction-column">
						<span class="two-line">{{ item.name }}</span>
						<p>{{ item.create_time }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {postAlbumDetail, postAlbumRecommend} from '@/service/api/index'

export default {
	data() {
		return {
			pageInfo: null,
			list: [],
			url: ''
		}
	},

	mounted() {
		window.addEventListener('beforeunload', this.beforeunloadFn())
		if (this.$route.params.info) {
			let info = this.$route.params.info
			sessionStorage.setItem('navbarId', info.id)
			this.postAlbumDetail(info.id)
		}
		this.postAlbumRecommend()
	},
	destroyed() {
		window.removeEventListener('beforeunload', this.beforeunloadFn())
	},

	methods: {
		postAlbumDetail(id) {
			postAlbumDetail({id}).then((res) => {
				this.pageInfo = Object.freeze(res.msg)
				this.url = this.pageInfo.image
				// console.log(this.pageInfo)
			})
		},
		postAlbumRecommend() {
			postAlbumRecommend().then((res) => {
				this.list = res.msg
				// console.log(res)
			})
		},
		swiperChange(index) {
			this.swiperIndex = index
		},
		relevantClick(id) {
			this.postAlbumDetail(id)
		},
		beforeunloadFn() {
			if (!this.$route.params.info) {
				let navbarId = sessionStorage.getItem('navbarId')
				this.postAlbumDetail(navbarId)
			}
		},
		choiceClick(id) {
			this.postAlbumDetail(id)
		},
		choiceImage(url) {
			this.url = url
			// console.log(this.pageInfo, url)
		}
	}
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/pictureANDvideo.less');

/deep/ .el-image {
	img {
		object-fit: contain;
	}
}

/* 滚动条宽度 */
::-webkit-scrollbar {
	height: 5px;
}

/* 滚动条背景颜色 */
::-webkit-scrollbar-track {
	background-color: #fff;
}

/* 滚动条当前背景颜色 */
::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
}

.image_list {
	overflow-x: auto;
	width: 940px;
	margin-top: 20px;

	img {
		width: 144px;
		height: 90px;
		border-radius: 3px;
		margin-right: 10px;
		object-fit: cover;
		cursor: pointer;

		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
